* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-align: center;
}
body {
  background-image: radial-gradient(
    circle farthest-corner at 10% 20%,
    rgba(0, 107, 141, 1) 0%,
    rgba(0, 69, 91, 1) 90%
  );
  background-size: cover;
  background-repeat: no-repeat;
  color: goldenrod;
  position: relative;
  padding-bottom: 50px;
  min-height: 100vh;
}
h1 {
  background-color: black;
  padding: 5px;
  margin: auto;
  margin-bottom: 30px;
  font-family: Arial, Helvetica, sans-serif;
}
.Nums {
  font-size: 40px;
  margin: 5px;
}
.container {
  max-width: 300px;
  padding: 10px 0px 10px 0px;
  margin: 0px auto;
  border: 2px solid goldenrod;
  border-radius: 5px;
  background-color: black;
}
*:focus {
  outline: 0 !important;
}
.head_buttons {
  margin: 5px;
  padding: 7px;
  font-size: 30px;
  border: 2px solid goldenrod;
  border-radius: 5px;
}
button {
  margin: 5px;
  padding: 5px;
  font-size: 20px;
  font-weight: bold;
  color: goldenrod;
  border: 2px solid goldenrod;
  border-radius: 5px;
  background-color: black;
}
button:hover {
  background-color: goldenrod;
  color: black;
  cursor: pointer;
}
button.start_style {
  color: green;
}
button.stop_style {
  color: red;
}

#Time_out {
  font-size: 20px;
  font-weight: bold;
  color: rgb(255, 0, 0);
  background-color: black;
  padding: 1px;
}
address {
  background-color: black;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 5px;
}
